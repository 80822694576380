import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Store from './Store'
import Frontpage from './Frontpage'
import Discounts from './Discounts'
import Categories from './Categories'
import Collections from './Collections'
import Products from './Products'
import Pages from './Pages'
import Menus from './Menus'
import Orders from './Orders'
import Users from './Users'
import SideBar from '../components/Header/SideBar'
import UserProfile from './UserProfile'
import UserGroups from './UserGroups'
import Dashboard from './Dashboard'
import ErrorsContainer from '../containers/errorsContainer'
import EmailTemplates from './EmailTemplates'
import EmailCampaigns from './EmailCampaigns'

import AuthStorage from '../services/authStorage'
import ProductInventoryContainer from "../containers/productInventoryContainer";
const Auth = new AuthStorage();

export default ({ match, store, history }) => (
  <div>
    {/* <Header store={store} history={history}/> */}
    <SideBar store={store} history={history}>
      <ErrorsContainer />
      <Switch>
        <Route exact path={`${match.path}/`} component={Dashboard} />
        { Auth.isSuperUser() && <Route path={`${match.path}/store`} component={Store} /> }
        <Route path={`${match.path}/frontpage`} component={Frontpage} />
        <Route path={`${match.path}/dashboard`} component={Dashboard} />
        <Route path={`${match.path}/menus`} component={Menus} />
        <Route path={`${match.path}/products`} component={Products} />
        <Route path={`${match.path}/inventory`} component={ProductInventoryContainer} />
        <Route path={`${match.path}/categories`} component={Categories} />
        <Route path={`${match.path}/orders`} component={Orders} />
        <Route path={`${match.path}/discounts`} component={Discounts}/>
        <Route path={`${match.path}/collections`} component={Collections} />
        <Route path={`${match.path}/menus`} component={Menus} />
        <Route path={`${match.path}/email-templates`} component={EmailTemplates} />
        <Route path={`${match.path}/email-campaigns`} component={EmailCampaigns} />
        {/*<Route path={`${match.path}/inventory`} component={ProductInventoryContainer} />*/}
        {/* {/* <Route path={`${match.path}/discounts`} component={Discounts} /> */}
        <Route path={`${match.path}/users`} component={Users} />
        <Route path={`${match.path}/pages`} component={Pages} />
        {/* { Auth.isSuperUser() && <Route path={`${match.path}/teams`} component={Teams} /> } */}
        <Route path={`${match.path}/userGroups`} component={UserGroups} />
        <Route path={`${match.path}/profile`} component={UserProfile} />
      </Switch>
    </SideBar>
  </div>
)
