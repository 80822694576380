import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as orderActions from '../actions/orderActions';
import { OrderApi } from '../api/orderApi';
import ShowOrder from '../components/Order/showOrder'
import * as notificationActions from '../actions/notificationAction'
import AuthStorage from '../services/authStorage'
const Auth = new AuthStorage()

const ShowOrderContainer = (props) => {
  const [order, setOrder] = useState(props.order)
  const [subtotal, setSubtotal] = useState(0)
  const [freeDelivery, setFreeDelivery] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState(null)
  const storeCurrency = Auth.getStore() ? Auth.getStore().defaultCurrency : { name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' }


  const fetchOrder = async (orderId) => {
    const response = await OrderApi.getOrder(orderId)
    if(response){
      let _order = response.data.order
      setSubtotal(response.data.subtotal)
      setOrder({..._order})
      setFreeDelivery(response.data.freeDelivery)
      if(_order.transaction_id) {
        OrderApi.paymentDetails(_order.transaction_id).then(res => {
          setPaymentDetails(res.data)
        })
      }
    }
  }

  useEffect(() => {
    if (props.order.id > 0) {
      fetchOrder(props.order.id)
    }
  }, [])

  const handleChange = async (e) => {
    const field = e.target.name
    try {
      const response = await OrderApi.updateOrder(order.id, {[field]: e.target.value})
      setOrder(response.data)
    }
    catch(error) {
      props.notifyActions.setErrorNotification({ message: error.response.data })
    }
  }

  return (
    <ShowOrder
      order={order}
      freeDelivery={freeDelivery}
      paymentDetails={paymentDetails}
      storeCurrency={storeCurrency}
      subtotal={subtotal}
      onChange={handleChange} />
  )
}

ShowOrderContainer.propTypes = {
  order: PropTypes.object.isRequired
}

const emptyOrder = () => {
  return {
    shipping_name: '',
    shipping_city: '',
    shipping_country: '',
    payment_status: '',
    status: '',
    order_items: [],
    order_tracking: {}
  }
}

const mapStateToProps = (state, ownProps) => {
  let order = emptyOrder()
  const orderId = Number(ownProps.match.params.id)
  
  if (orderId > 0) {
    order.id = orderId
    if (state.orders.data.length > 0) {
      order = { ...order, ...state.orders.data.find(order => order.id === orderId) }
    }
  }
  return {
    order: order
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(orderActions, dispatch),
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowOrderContainer)
