import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Box } from '@mui/material'

const AlignStyle = Quill.import('attributors/style/align')
const BackgroundStyle = Quill.import('attributors/style/background')
const ColorStyle = Quill.import('attributors/style/color')
const FontStyle = Quill.import('attributors/style/font')
const SizeStyle = Quill.import('attributors/style/size')

Quill.register(AlignStyle, true)
Quill.register(BackgroundStyle, true)
Quill.register(ColorStyle, true)
Quill.register(FontStyle, true)
Quill.register(SizeStyle, true)

const TextEditor = (props) => {
  const quillRef = useRef(null)
  const [value, setValue] = useState('')

  useEffect(() => {
    if (props.value && value !== props.value) {
      setValue(props.value)
    }
  }, [props.value])

  const handleChange = (content) => {
    setValue(content)
    if (props.onChange) {
      props.onChange(content, props.name)
    }
  }

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ font: [] }],
        [{ align: ['right', 'center', 'justify'] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        [{ color: [] }, { background: [] }],
      ],
    },
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: props.rows ? `${props.rows * 20}px` : 'auto',
        maxHeight: '400px',
        overflow: 'auto',
        '& .ql-container': {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'auto',
        },
      }}
    >
      <ReactQuill
        ref={quillRef}
        modules={modules}
        theme="snow"
        value={value}
        onChange={handleChange}
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      />
    </Box>
  )
}

TextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  name: PropTypes.string,
}

export default TextEditor