import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Link } from '@mui/material'
import ImageThumbnail from '../Shared/ImageThumbnail'

const Item = props => (
  <Grid container spacing={2} sx={{padding: "3%"}}>
    <Grid container item={true} xs={10} md={10} lg={10} xl={10}>
      <Grid item xs={3}>
        <ImageThumbnail image={props.product.images.length > 0 ? props.product.images[0] : null} width='110px' height='110px' size='80x80' />
      </Grid>
      <Grid item sx={{paddingLeft: '2%'}}>
        <Link target='_blank' href={`/admin/products/edit/${props.product.id}`}>
          <label>{props.product.name}</label>
        </Link>
        <p>{props.variant.name} {`${props.variant.article ? ' - ' + props.variant.article : ''}`}</p>
        <p>{props.quantity} x {props.price} {props.storeCurrency.symbol}</p>
      </Grid>
    </Grid>
    <Grid item xs={2} md={2} lg={2} xl={2}>
      <p sx={{float: 'right'}}>{Math.round((props.quantity * props.price) * 100) / 100} {props.storeCurrency.symbol}</p>
    </Grid>
  </Grid>
)

Item.propTypes = {
  id: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  product: PropTypes.object,
  variant: PropTypes.object,
  storeCurrency: PropTypes.object.isRequired
}

export default Item
