import React from 'react';

const BackgroundPreview = ({ object, initialColor }) => {
  const previewStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    height: '300px',
    backgroundImage: `url('${object.image_preview_url || object.image}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    color: object.text_color || initialColor,
    textAlign: 'center',
    padding: '0 20px',
  };

  return (
    <div style={previewStyle}>
      <h2>{object.name}</h2>
      <p>{object.description}</p>
    </div>
  );
};

export default BackgroundPreview;
