import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, FormControlLabel, Grid, Checkbox, Typography, FormHelperText, InputLabel, FormControl } from '@mui/material';
import TextEditor from '../Helpers/TextEditor'
import FieldGroup from '../fieldGroup'
import EditPageUpperContainer from '../Shared/EditPageHeader';
import EditPageLowerContainer from '../Shared/EditPageLowerContainer';
import RightImagePanel from '../Shared/UploadImageWidget';
import BackgroundPreview from '../Shared/BackgroundPreview';
import ColorDropdown from '../Shared/ColorDropdown';
import useTranslation from '../../hooks/useTranslation'

const EditCollection = ({ collection, validationErrors, isSaving, onChange, onSave, removeImage }) => {
const { t } = useTranslation()
  const actionType = ( collection?.hasOwnProperty('id') && collection.id > 0 ) ? 'Edit' : 'New' 
  const colorOptions = [
    { value: '#000', label: 'Black' },
    { value: '#fff', label: 'White' }
  ];

  const leftFirstContent = () => (
    <>
      <Grid item xs={12} md={9}>
        <FieldGroup
          id="collectionName"
          type="text"
          label={t('labels.name')}
          name="name"
          value={collection.name}
          validationState={ validationErrors.hasOwnProperty('name') ? 'error' : null}
          placeholder={t('labels.name')}
          onChange={onChange} />
      </Grid>
      <Grid item xs={12} md={12}>
        <FieldGroup
          id="collectionDescription"
          type="text"
          label={t('labels.description')}
          name="description"
          value={collection.description}
          placeholder="Enter Description"
          onChange={onChange}
          multiline
          rows={6} />
      </Grid>
      <Grid item xs={12} md={12}>
          <FormGroup>
          <InputLabel>{t('labels.long_description')}</InputLabel>
            <FormControl>
              <TextEditor rows={10} value={collection.information || ''} onChange={onChange} />
            </FormControl>      
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={12}>
          <InputLabel>{t('labels.preview')}</InputLabel>
          <BackgroundPreview object={collection} initialColor='#000'/>
      </Grid>
    </>
  )

  const rightFirstContent = () => (
    <>
      <Grid item xs={12} md={12}>
        <Typography gutterBottom variant="h5" component="h2">
        {t('labels.visibility')}
        </Typography>
      </Grid>
      <Grid item xs={8} md={8} sx={{ height: '30px' }}>
        <FormGroup>
          <FormControlLabel control={<Checkbox name="show_on_homepage" color="primary" checked={collection?.show_on_homepage} onChange={onChange} />} label={t('labels.show_on_homepage')} />
        </FormGroup>
      </Grid>
      <FormHelperText>{t('labels.collection_display')}</FormHelperText>
      <Grid item xs={4} md={12}  sx={{ height: '30px' }}>
        <FormGroup>
          <FormControlLabel control={<Checkbox name="visible" color="primary" checked={collection?.visible} onChange={onChange} />} label={t('labels.public')} />
        </FormGroup>
      </Grid>
      <FormHelperText>{t('labels.unchecked_public')}</FormHelperText>
    </>
  )

  const rightSecondContent = () => (
    <RightImagePanel object={collection} onChange={onChange} deleteImage={removeImage}/>
  )

  const rightThirdContent = () => (
    <ColorDropdown text_color={collection.text_color} onChange={onChange} options={colorOptions} />
  )

  return (
    <div>
      <EditPageUpperContainer actionType={actionType} page={t('dependentSource.collection')} onSave={onSave} />
        <form onSubmit={onSave}>
          <EditPageLowerContainer
            leftContent={ [leftFirstContent] }
            rightContent={ [rightFirstContent, rightSecondContent, rightThirdContent] }
            actionType={actionType}
            onSave={onSave}
          />
        </form> 
    </div>
)}

EditCollection.propTypes = {
  collection: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default EditCollection
