import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import OrderItems from './orderItems'
import { OrderApi } from '../../api/orderApi'
import Summary from './summary'
import OrderTrackingDetails from './orderTrackingDetails'
import { Grid, Button, Chip, Divider, Paper, TextField, MenuItem } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import useTranslation from '../../hooks/useTranslation'
import { OrderNotes, AddOrderNote } from '../../shared-components';

const fetchDownloadUrl = id => {
  OrderApi.getOrderDeliveryNote(id)
    .then(res => {
      if (res.status===200) {
        let a = document.createElement('a')
        a.href = res.data.url
        a.download = 'employees.json'
        a.click()
      }
    })
}

const fetchDownloadInvoiceUrl = id => {
  OrderApi.getOrderInvoice(id)
    .then(res => {
      if (res.status===200) {
        let invoice = document.createElement('a')
        invoice.href = res.data.url
        invoice.download = 'employees.json'
        invoice.click()
      }
    })
}

const ShowOrder = props => {
  const { t } = useTranslation()
  const [notes, setNotes] = useState([]);
  const [user, setUser] = useState(null);
  const [totalNotesCount, setTotalNotesCount] = useState(0);

  useEffect(() => {
    loadNotes(props.order.id, 1);
  }, [props.order.id]);

  const loadNotes = (orderId, pageNumber) => {
    return OrderApi.getOrderNotes(orderId, pageNumber).then(response => {
      setUser(response.data.user_id)
      if (pageNumber === 1) {
        setNotes(response.data.notes);
        setTotalNotesCount(response.data.totalNotesCount);
      } else {
        setNotes(prevNotes => [...prevNotes, ...response.data.notes]);
      }
      return response.data;
    });
  };

  const addNote = (orderId, note) => {
    return OrderApi.addOrderNote(orderId, { note });
  };

  const handleNoteAdded = newNote => {
    setNotes(prevNotes => [newNote, ...prevNotes]);
  };

  const editNote = (orderId, noteId, note) => {
    return OrderApi.editOrderNote(orderId, noteId, note);
  };

  const deleteNote = (orderId, noteId) => {
    return OrderApi.deleteOrderNote(orderId, noteId);
  };

  let customerName
  if(props.order.user) {
    customerName = <a target="_blank" href={`/admin/users/edit/${props.order.user_id}`} rel="noreferrer">{props.order.user.name}</a>
  }
  else if(props.order.guest_checkout) {
    customerName = <a target="_blank" href={`/admin/users/edit/${props.order.guest_checkout.user_id}`} rel="noreferrer">{`${props.order.guest_checkout.name} (${t('labels.guest_checkout')})`}</a>
  }
  else {
    customerName = t('labels.guest_checkout')
  }

  const styles = {
    paper: {
      paddingRight: 0,
      width: '100%',
      border: '1px solid lightgrey'
    },
    actionBtn: {
      marginLeft: '0.75rem',
      borderColor: 'lightgrey'
    }
  }
  
  return (
    <Grid container>
      <Grid container item={true} xs={12} md={12} lg={12} xl={12} alignItems="center">
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <h1>{`${t('labels.order')} - #${props.order.id}`}</h1>
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6} textAlign="end">
          <Button sx={styles.actionBtn} variant="outlined" href={`/admin/orders/${props.order.id}/edit`}>
            <EditIcon />&nbsp;{t('labels.edit_order')}
          </Button>
          <Button sx={styles.actionBtn} variant="outlined" onClick={() =>fetchDownloadUrl(props.order.id)}>
            <DownloadIcon />&nbsp;{t('labels.delivery_note')}
          </Button>
          <Button sx={styles.actionBtn} variant="outlined" onClick={() => fetchDownloadInvoiceUrl(props.order.id)}>
            <DownloadIcon />&nbsp;{t('labels.pdf')}
          </Button>
        </Grid>
      </Grid>
      <Divider width="100%" />
      <br/>
      <Grid container spacing={3}>
        <Grid container item xs={12} md={8} lg={8} xl={8} direction="column">
          <Paper sx={styles.paper}>
            <OrderItems order_items={props.order.order_items} storeCurrency={props.storeCurrency} order={{...props.order}} />
          </Paper>
          <br/>
          <Summary
            subtotal={props.subtotal}
            order={props.order}
            freeDelivery={props.freeDelivery}
            storeCurrency={props.storeCurrency}
            pricesIncludeVat={props.order.store && props.order.store.prices_include_vat} />
          <Paper sx={{ padding: '1rem', marginTop: '1rem' }}>
            <Grid container>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <h3>{t('labels.notes')}</h3>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <AddOrderNote
                  orderId={props.order.id}
                  addNote={addNote}
                  onNoteAdded={handleNoteAdded}
                  label={t('labels.quick_note')}
                  button_text={t('labels.save')}
                  helperText={t('labels.note_helper_text')}
                />
              </Grid>
              <Grid item xs={12}>
                <OrderNotes
                  orderId={props.order.id}
                  initialNotes={notes}
                  loadNotes={loadNotes}
                  editNote={editNote}
                  deleteNote={deleteNote}
                  userId={user}
                  button_text={t('labels.save')}
                  totalNotesCount={totalNotesCount}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item xs={12} md={4} lg={4} xl={4} direction="column">
          <Paper sx={styles.paper}>
            <Grid container item={true} spacing={3} sx={{padding: "3%"}}>
              <Grid item xs={12} md={12} lg={12} xl={12} sx={{paddingLeft: "3%"}}>
                <h4>{t('labels.status')}</h4>
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField
                  name="payment_status"
                  label="Payment"
                  value={props.order.payment_status || ''}
                  disabled={props.order.payment_status === 'paid'}
                  onChange={props.onChange}
                  placeholder="Select"
                  variant='outlined'
                  select
                  fullWidth>
                  <MenuItem value=''>Select</MenuItem>
                  <MenuItem value='unpaid'>Unpaid</MenuItem>
                  <MenuItem value='paid'>Paid</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField
                  name="payment_method"
                  label={t('labels.method')}
                  value={props.order.payment_method_value?.toLowerCase() || ''}
                  onChange={props.onChange}
                  placeholder="Select"
                  variant='outlined'
                  select
                  fullWidth>
                  <MenuItem value=''>Select</MenuItem>
                  <MenuItem value='credit_card'>Credit Card</MenuItem>
                  <MenuItem value='discount_payment'>Discount Voucher</MenuItem>
                  <MenuItem value='free'>Free</MenuItem>
                  <MenuItem value='invoice'>Invoice</MenuItem>
                  <MenuItem value='user_credit'>Account Balance</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField
                  name="status"
                  label="Order Status"
                  value={props.order.status || ''}
                  onChange={props.onChange}
                  placeholder="Select"
                  variant='outlined'
                  select
                  fullWidth>
                  <MenuItem value=''>Select</MenuItem>
                  <MenuItem value="processing">Processing</MenuItem>
                  <MenuItem value="ready">Ready To Ship</MenuItem>
                  <MenuItem value="shipped">Shipped</MenuItem>
                  <MenuItem value="complete">Complete</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Paper>
          <br />
          {props.paymentDetails && (
            <>
              <Paper sx={styles.paper}>
                <Grid container spacing={3} sx={{padding: "3%"}}>
                  <Grid item xs={12} sx={{paddingLeft: "3%"}}>
                    <h4>{t('labels.payment_details')}</h4>
                  </Grid>
                  { props.paymentDetails.last_payment_error && (
                    <Grid item xs={12}>
                      { t(('labels.payment_failed')) }
                      <div>Reason: <code>{props.paymentDetails.last_payment_error.message}</code></div>
                      <div>Code: <code>{props.paymentDetails.last_payment_error.code}</code></div>
                    </Grid>
                  )}
                  { props.paymentDetails.paymentMethods.map(paymentMethod => (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={8}>
                          {t('labels.paid_with')} { paymentMethod.type=='card' ? `${paymentMethod.brand} ${t('labels.card')}` : paymentMethod.name}
                          {/* { paymentMethod.brand && <div>{`${t('labels.paid_with')} ${paymentMethod.brand} ${t('labels.card')}`}</div>} */}
                          { paymentMethod.last4 && <div>{`**** **** **** ${paymentMethod.last4}`}</div>}
                        </Grid>
                        <Grid item xs={4} textAlign="right">{`${paymentMethod.amount_captured} ${props.paymentDetails.currency}`}</Grid>
                        </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
              <br />
            </>
          )}
          <Paper sx={styles.paper}>
            <Grid item sx={{padding: '3%'}}>
              <h4>{t('labels.customer')}</h4>
              <p>{customerName}</p>
              <label>{t('labels.contact')}</label>
              <p>{props.order.name}</p>
              <p>{props.order.phone}</p>
              <label>{t('labels.shipping_address')}</label>
              <p>{props.order.shipping_name}</p>
              {props.order.shipping_company_name && <p>{props.order.shipping_company_name}</p>}
              {props.order.shipping_company_name && <p>{props.order.shipping_organisation_number}</p>}
              <p>{props.order.email}</p>
              <p>{props.order.shipping_address_one}, {props.order.shipping_address_two}</p>
              <p>{props.order.shipping_postcode}, {props.order.shipping_city}, {props.order.shipping_country}</p>
              { props.order.delivery_instructions ? <p><b>{t('labels.delivery_instructions_label')}:</b> {props.order.delivery_instructions}</p> : null }
              <label>{t('labels.invoice_address')}</label>
              <p>{props.order.invoice_name}</p>
              {props.order.invoice_company_name && <p>{props.order.invoice_company_name}</p>}
              {props.order.invoice_company_name && <p>{props.order.invoice_organisation_number}</p>}
              <p>{props.order.invoice_email}</p>
              <p>{props.order.invoice_address_one}, {props.order.invoice_address_two}</p>
              <p>{props.order.invoice_postcode}, {props.order.invoice_city}, {props.order.invoice_country}</p>
              { props.order.invoice_reference ? <p><b>{t('labels.reference')}:</b> {props.order.invoice_reference}</p> : null }
              { props.order.invoice_instructions ? <p><b>{t('labels.billing_instructions')}:</b> {props.order.invoice_instructions}</p> : null }
            </Grid>
          </Paper>
          <br />
          <Paper sx={styles.paper}>
            <Grid item sx={{padding: '3%'}}>
              <OrderTrackingDetails order={props.order}/>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

ShowOrder.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    invoice_number: PropTypes.number,
    shipping_name: PropTypes.string,
    shipping_company_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    shipping_address_one: PropTypes.string,
    shipping_address_two: PropTypes.string,
    shipping_postcode: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_country: PropTypes.string,
    invoice_name: PropTypes.string,
    invoice_company_name: PropTypes.string,
    invoice_address_one: PropTypes.string,
    invoice_address_two: PropTypes.string,
    invoice_postcode: PropTypes.string,
    invoice_city: PropTypes.string,
    invoice_country: PropTypes.string,
    invoice_instructions: PropTypes.string,
    invoice_reference: PropTypes.string,
    payment_status: PropTypes.string,
    payment_method: PropTypes.string,
    payment_method_value: PropTypes.string,
    paid_at: PropTypes.string,
    status: PropTypes.string,
    delivery_instructions: PropTypes.string,
    order_items: PropTypes.array.isRequired,
    discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    freight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    net_total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    store: PropTypes.object,
    coupon: PropTypes.object,
    order_discount: PropTypes.object,
    use_account_balance: PropTypes.bool,
    balance_used: PropTypes.string,
    user: PropTypes.object,
    user_id: PropTypes.number,
    shipping_organisation_number: PropTypes.string,
    name: PropTypes.string,
    invoice_email: PropTypes.string,
    invoice_organisation_number: PropTypes.string,
    guest_checkout: PropTypes.object
  }),
  paymentDetails: PropTypes.object,
  subtotal: PropTypes.number.isRequired,
  freeDelivery: PropTypes.bool,
  onChange: PropTypes.func,
  storeCurrency: PropTypes.object.isRequired
}

export default ShowOrder