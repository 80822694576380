import React, { useState, useRef } from 'react'
import { OrderApi } from '../api/orderApi'
import ListingPage from '../components/Shared/ListingPage'
import { ListTable } from '../components/Shared/ListingTable'
import Order from '../components/Order/order'
import AuthStorage from '../services/authStorage'
import { CSVLink } from 'react-csv'
import { Grid, Button } from '@mui/material'
import ListTableActions from '../components/Shared/ListingTable/listTableActions'
import { orderSorting } from '../config/sortingLabels'
import useListingActions from '../hooks/useListingActions'
import { useHistory } from 'react-router-dom'
import useTranslation from '../hooks/useTranslation'

const styles = {
  actionBtn: {
    textTransform: 'none',
    marginTop: '2%',
    float: 'right'
  }
}

const OrderContainer = (props) => {
  const [ordersForExport, setOrdersForExport] = useState([])
  const csvLink = useRef()
  const colsToRemove = ['paid_at', 'team_checkout', 'user', 'id']
  const history = useHistory()
  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
  } = useListingActions('orders', OrderApi.getOrders);
  const { t } = useTranslation()

  const fetchOrdersForExport = async () => {
    const result = await OrderApi.getOrdersForExport({ q: searchTerm, sort_by: sortOptionSelected })
    const exportData = result.data.flatMap(order => {
      return [
        formatOrderRow(order),
        ...formatOrderItemsRows(order.order_items, order.id)
      ];
    });
    setOrdersForExport(exportData)
    csvLink.current.link.click()
  }
  
  const formatOrderRow = (order) => ({
    order_id: order.id,
    customer_name: order.user?.name,
    customer_email: order.user?.email,
    customer_contact: order.user?.phone,
    shipping_name: order.shipping_name,
    shipping_city: order.shipping_city,
    shipping_country: order.shipping_country,
    net_total: order.net_total,
    freight: order.freight,
    vat: order.vat,
    discount: order.discount,
    balance_used: order.balance_used,
    payment_status: order.payment_status,
    status: order.status,
    createdAt: (new Date(order.createdAt)).toLocaleDateString('sv'),
    product_name: '',
    product_price: '',
    variant_name: '',
    variant_price: '',
    variant_sku: '',
    quantity: '',
  })
  
  const formatOrderItemsRows = (orderItems, orderId) => {
    return orderItems.map(item => ({
      order_id: orderId,
      customer_name: '',
      customer_email: '',
      customer_contact: '',
      shipping_name: '',
      shipping_city: '',
      shipping_country: '',
      net_total: '',
      freight: '',
      vat: '',
      discount: '',
      balance_used: '',
      payment_status: '',
      status: '',
      createdAt: '',
      product_name: item.product.name,
      product_price: item.product.price,
      variant_name: item.variant?.name || 'No Variant',
      variant_price:  item?.variant?.price || '',
      variant_sku: item?.variant?.sku || '',
      quantity: item.quantity,
    }))
  }
  
  const newOrder = () => {
    history.push('/admin/orders/new')
  }

  const tableActions = (
    <ListTableActions
      searchTerm={searchTerm}
      searchText={t('labels.search_orders')}
      sortOptionSelected={sortOptionSelected}
      translateText={t}
      getData={handlerForSearchingAndSortingChange}
      sortOptions={orderSorting}
    />
  )

  const Auth = new AuthStorage()
  const storeCurrency = Auth.getStore() ? Auth.getStore().defaultCurrency : { name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' }

  const tableHeading = [
    t('labels.order'),
    t('labels.date'),
    t('labels.customer'),
    t('labels.city'),
    t('labels.country'),
    t('labels.total'),
    t('labels.payment'),
    '',
    ''
  ];

  const tableContent = data.map((order, index) => (
    <Order key={order.id} {...order} storeCurrency={storeCurrency} />
  ));

  const actionButtons = (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item>
        <Button variant="outlined" style={styles.actionBtn} onClick={fetchOrdersForExport}>{t('labels.exportToCsv')}</Button>
        <CSVLink
          data={ordersForExport}
          filename={`orders-${(new Date()).toLocaleDateString().replace(', ', '-')}.csv`}
          className='hidden'
          ref={csvLink}
          target='_blank' />
      </Grid>
      <Grid item>
        <Button variant="contained" sx={styles.actionBtn} onClick={newOrder}>{t('labels.create_order')}</Button>
      </Grid>
    </Grid>
  )

  return (
    <ListingPage
      title={t('labels.orders')}
      actions={actionButtons} 
      table={(
        <ListTable
          tableHeadings={tableHeading}
          tableContent={tableContent}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsControl={true}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsPerPage={perPage || t('perPage.orders')} />
      )} />
  )
}

export default OrderContainer
