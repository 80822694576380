import React, { useState }  from 'react'
import PropTypes from 'prop-types'
import TextEditor from '../Helpers/TextEditor'
import { FormGroup, FormControlLabel, InputLabel, FormControl, Grid, Button, Checkbox, FormHelperText, Typography, Divider, TextField, InputAdornment } from '@mui/material';
import FieldGroup from '../fieldGroup'
import EditPageUpperContainer from '../Shared/EditPageHeader';
import EditPageLowerContainer from '../Shared/EditPageLowerContainer';
import RightImagePanel from '../Shared/UploadImageWidget';
import ViewInStoreButton from '../Shared/ViewInStoreButton';
import BackgroundPreview from '../Shared/BackgroundPreview';
import ColorDropdown from '../Shared/ColorDropdown';
import useTranslation from '../../hooks/useTranslation'

const EditCategory = props => {
  const { t } = useTranslation()
  const actionType = ( props.category?.hasOwnProperty('id') && props.category?.id > 0 ) ? 'Edit' : 'New' 
  const colorOptions = [
    { value: '#000', label: 'Black' },
    { value: '#fff', label: 'White' }
  ];

  const leftFirstContent = () => (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <FieldGroup
            id="categoryName"
            type="text"
            label={t('labels.name')}
            name="name"
            value={props.category.name}
            validationState={props.validationErrors.hasOwnProperty('name') ? 'error' : null}
            placeholder={t('labels.name')}
            onChange={props.onChange} />
        </Grid>
        <Grid item xs={12} md={12}>
          <FieldGroup
            id="categoryDescription"
            type="text"
            label={t('labels.description')}
            name="description"
            value={props.category.description || ''}
            placeholder="Short description"
            onChange={props.onChange}
            multiline
            rows={6}/>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormGroup>
          <InputLabel>{t('labels.long_description')}</InputLabel>
            <FormControl>
              <TextEditor rows={10} value={props.category.information} onChange={props.onInformationChange} />
            </FormControl>  
        </FormGroup>
        </Grid>
        <Grid item xs={12} md={12}>
          <InputLabel>{t('labels.preview')}</InputLabel>
          <BackgroundPreview object={props.category} initialColor='#000'/>
        </Grid>
      </Grid>
    </>
  )

  const rightFirstContent = () => (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography gutterBottom variant="h5" component="h2">{t('labels.visibility')}</Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          {props.category.visible && <ViewInStoreButton path={`/${props.category.slug}`} />}
        </Grid>
      </Grid>
      <Grid item xs={8} md={12}  sx={{ height: '30px' }}>
        <FormGroup>
          <FormControlLabel control={<Checkbox name="show_on_homepage" color="primary" checked={props.category.show_on_homepage} onChange={props.onChange}/>} label={t('labels.show_on_homepage')} />
        </FormGroup>
      </Grid>
      <FormHelperText>{t('labels.top_products')}</FormHelperText>
      <Grid item xs={4} md={2} sx={{ height: '30px' }}>
        <FormGroup>
          <FormControlLabel control={<Checkbox name="visible" color="primary" checked={props.category.visible} onChange={props.onChange}/>} label={t('labels.public')} />
        </FormGroup>
      </Grid>
      <FormHelperText>{t('labels.unchecked_public')}</FormHelperText>
    </>
  )

  const rightSecondContent = () => (
    <RightImagePanel object={props.category} onChange={props.onChange} deleteImage={props.removeImage}/>
  )

  const rightThirdContent = () => (
    <ColorDropdown text_color={props.category.text_color} onChange={props.onChange} options={colorOptions} />
  )

  return (
    <div>
      <EditPageUpperContainer actionType={actionType} page={t('labels.category')} onSave={props.onSave}/>
      <form>
        <EditPageLowerContainer
          leftContent={[leftFirstContent]}
          rightContent={[ rightFirstContent, rightSecondContent, rightThirdContent ]}
          actionType={actionType}
          onSave={props.onSave}
        />
      </form>
    </div>
  )
}

EditCategory.propTypes = {
  category: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onInformationChange: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default EditCategory
