import { HttpClient } from './httpClient'
import { API_URL } from './RestAPI'
import apiHeaders from './apiHeaders'
import {setUrlForGetRequest} from './sharedFunctions'

//Setting the orders API URI
const ORDER_API = `${API_URL}/orders`

// Create
const createOrder = order => {
  return HttpClient.post(
    `${ORDER_API}/create`,
    order,
    apiHeaders()
  )
}

// Read
const getOrders = (params, dependent = {}) => {
  let url = setUrlForGetRequest(ORDER_API, params)
  return HttpClient.get(url, apiHeaders(dependent))
}

const getOrdersForExport = (params) => {
  return HttpClient.get(
    `${ORDER_API}/orders-for-export?q=${params.q}&sort_by=${params.sort_by}`,
    apiHeaders()
  )
}

// Get
const getOrder = id => {
  return HttpClient.get(
    `${ORDER_API}/${id}`,
    apiHeaders()
  )
}

// Get
const getOrderDeliveryNote = id => {
  return HttpClient.get(
    `${ORDER_API}/${id}/delivery-note`,
    apiHeaders()
  )
}

// Get
const getOrderInvoice = id => {
  return HttpClient.get(
    `${ORDER_API}/${id}/invoice`,
    apiHeaders()
  )
}

// Update
const updateOrder = (id, order) => {
  return HttpClient.put(
    `${ORDER_API}/${id}/update`,
    order,
    apiHeaders()
  )
}

// Delete
const removeOrder = page => {
  return HttpClient.delete(
    `${ORDER_API}/${page.id}`,
    apiHeaders()
  )
}

const updateOrderItem = (data) => {
  return HttpClient.put(
    `${ORDER_API}/update-order-item`,
    data,
    apiHeaders()
  )
}

const removeOrderItem = (data) => {
  return HttpClient.put(
    `${ORDER_API}/remove-order-item`,
    data,
    apiHeaders()
  )
}

const updateOrderDiscount = (data) => {
  return HttpClient.put(
    `${ORDER_API}/update-order-discount`,
    data,
    apiHeaders()
  )
}

const removeOrderDiscount = (data) => {
  return HttpClient.put(
    `${ORDER_API}/remove-order-discount`,
    data,
    apiHeaders()
  )
}

const updateShippingCountry = (data) => {
  return HttpClient.put(
    `${ORDER_API}/update-shipping-country`,
    data,
    apiHeaders()
  )
}

// fetch payment details from stripe
const paymentDetails = (transaction_id) => {
  return HttpClient.put(
    `${ORDER_API}/payment-details`,
    { transaction_id: transaction_id },
    apiHeaders()
  )
}

const updateOrderTracking = (order_id, orderTrackingData) => {
  return HttpClient.post(
    `${ORDER_API}/${order_id}/update-order-tracking`,
    orderTrackingData,
    apiHeaders()
  )
}

// Add Order Note
const addOrderNote = (orderId, note) => {
  return HttpClient.post(
    `${ORDER_API}/${orderId}/notes`,
    note,
    apiHeaders()
  )
}

// Get Order Notes
const getOrderNotes = (orderId, page) => {
  return HttpClient.get(
    `${ORDER_API}/${orderId}/notes?page=${page}`,
    apiHeaders()
  )
}

// Edit Order Note
const editOrderNote = (orderId, noteId, note) => {
  return HttpClient.put(
    `${ORDER_API}/${orderId}/notes/${noteId}`,
    { note },
    apiHeaders()
  )
}

// Delete Order Note
const deleteOrderNote = (orderId, noteId) => {
  return HttpClient.delete(
    `${ORDER_API}/${orderId}/notes/${noteId}`,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const OrderApi = {
  createOrder,
  getOrders,
  getOrder,
  getOrderDeliveryNote,
  getOrderInvoice,
  updateOrder,
  removeOrder,
  updateOrderTracking,
  getOrdersForExport,
  updateOrderItem,
  removeOrderItem,
  updateOrderDiscount,
  removeOrderDiscount,
  updateShippingCountry,
  paymentDetails,
  addOrderNote,
  getOrderNotes,
  editOrderNote,
  deleteOrderNote
}

export { OrderApi }
